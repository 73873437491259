import { groupBy, round, uniq, uniqBy } from 'lodash';
import { action, computed, observable } from 'mobx';
import { EmailAttachment, IEntityEmailMixin } from 'react-core-communication/src/store/EmailMessage';
import { InvoiceLine, InvoiceLineStore } from 'react-logistics-finance/src/store/InvoiceLine';
import { Customer, Customer as LogisticsCustomer } from 'react-logistics-administration/src';
import { DossierStore } from 'react-logistics-administration/src/store/Dossier';
import { SelfBillStore } from 'react-logistics-finance/src/store/SelfBill';
import { Casts, Model, Store } from '@code-yellow/spider';
import { DateTime } from 'luxon';
import InvoiceStatus from 'react-logistics-finance/src/store/enums/InvoiceStatus';
import InvoiceLanguage from 'react-logistics-finance/src/store/enums/InvoiceLanguage';
import { VatCodeDe, VatCodeEn, VatCodeNl } from 'react-core-administration/src/store/enums/VatCode';
import { Contract } from 'react-logistics-finance/src/store/Contract';
import { Entity } from 'react-core-administration/src/store/Entity';
import InvoicelineType from 'react-logistics-finance/src/store/enums/InvoiceLineType';
import { Ledger } from 'react-logistics-finance/src/store/Ledger';
import { getVatPercentage } from 'react-core-administration/src/helpers';

export class Invoice<L extends InvoiceLine = InvoiceLine> extends Model implements IEntityEmailMixin {
    static backendResourceName = 'invoice';
    static omitFields = ['totalAmount', 'subtotalAmount', 'dossiers', 'invoicedTrips', 'invoicedDossiers', 'invoicedSelfBillItems', 'logisticsCustomer', 'selfBills'];

    @observable id: number | null = null;

    @observable number = '';
    @observable issueDate: DateTime | null = null;
    @observable dueDate = null;
    @observable reference = '';
    @observable status: InvoiceStatus = InvoiceStatus.DRAFTED;
    @observable sentAt = null;
    @observable remarks = '';
    @observable notes = '';
    @observable language = InvoiceLanguage.ENGLISH;
    @observable vatCode: VatCodeDe | VatCodeEn | VatCodeNl | null = null;

    @observable logisticsCustomer = this.relation(LogisticsCustomer);
    @observable dossiers = this.relation(DossierStore);
    @observable selfBills = this.relation(SelfBillStore);
    @observable contract = this.relation(Contract);
    @observable lines = this.relation(InvoiceLineStore<L>);
    @observable customer = this.relation(Customer);
    @observable entity = this.relation(Entity);
    @observable ledger = this.relation(Ledger);

    // These come from a reaction and are used in the overview
    @observable totalAmount = 0;
    @observable subtotalAmount = 0;

    casts() {
        return {
            issueDate: Casts.luxonDate,
            dueDate: Casts.luxonDate,
            sentAt: Casts.luxonDatetime,
        }
    }

    // These are used to calculate the total amount for the edit view
    @computed get totalAmountComputed() {
        return round(this.lines.models.reduce((total, line) => total + line.totalAmount, 0), 0);
    }

    @computed get subtotalAmountComputed() {
        return round(this.lines.models.reduce((total, line) => total + (line.amount ?? 0), 0), 0);
    }

    // Little helper to setup the view easier
    @observable __fetched = false;
    fetch(options?: object) {
        return super.fetch(options).then((data)=>{
            this.__fetched = true;
            return data;
        })
    }

    @computed get pdfPreviewUrl() {
        return `/api${this.url}pdf/`;
    }

    @computed get pdfDownloadUrl() {
        return `${this.pdfPreviewUrl}?download=true`;
    }

    async getAvailableAttachments() {
        const res = await this.api.get<EmailAttachment[]>(`${this.url}get_available_attachments/`);
        return res.data;
    }

    @computed get dossierDocumentsVirtualPathRegex() {
        return `^/dossier/(?:${this.dossiers.map(d=>d.dossierNumber).join('|') || -1})/$`;
    }

    @computed get invoicedSelfBillItems() {
        return this.lines.filter(line => !line.linkedSelfBillItem?.isNew).map(line => line.linkedSelfBillItem);
    }

    @computed get invoicedTrips() {
        return this.lines.filter(line => !line.linkedTrip?.isNew).map(line => line.linkedTrip);
    }

    @computed get invoicedDossiers() {
        return uniqBy(this.invoicedTrips.map(trip => trip.dossier), 'id');
    }

    addCustomLine(lineData): L {
        return this.addLine({
            ...lineData,
            type: InvoicelineType.CUSTOM,
        });
    }
    addLine(lineData): L {
        const ordering = this.getLinesByGroup(lineData.group ?? 0).length;
        return this.lines.add({ ordering, vat: getVatPercentage(this.entity.invoiceTemplate, this.vatCode), ...lineData });
    }

    removeLine(line) {
        this.lines.remove(line);
        this.fixOrderingWithinGroup(line.group);
    }

    removeLineGroup(group: number) {
        this.getLinesByGroup(group).forEach(line => this.lines.remove(line));
        this.fixGroupsOrdering();
    }

    getGroupTotalAmount(group: number): number {
        return round(this.getGroupAmount(group) + this.getGroupVat(group), 0);
    }

    getGroupAmount(group: number): number {
        return round(this.getLinesByGroup(group).reduce((total, line) => total + (line.amount ?? 0), 0), 0);
    }

    getGroupVat(group: number): number {
        return round(this.getLinesByGroup(group).reduce((total, line) => total + line.vatAmount, 0), 0);
    }

    getLinesByGroup(group: number) {
        return this.lines.models.filter(line => line.group === group).sort((a, b) => a.ordering - b.ordering);
    }

    @action
    moveLineUpWithinGroup(lineToMove) {
        if (lineToMove.ordering === 0) {
            return;
        }
        const linesInGroup = this.getLinesByGroup(lineToMove.group);

        const lineAbove = linesInGroup[lineToMove.ordering - 1];

        lineAbove.ordering++;
        lineToMove.ordering--;
    }

    @action
    moveLineDownWithinGroup(lineToMove) {
        const linesInGroup = this.getLinesByGroup(lineToMove.group);
        const maxOrdering = linesInGroup.length - 1

        if (lineToMove.ordering >= maxOrdering) {
            return;
        }

        const lineBelow = linesInGroup[lineToMove.ordering + 1];

        lineBelow.ordering--;
        lineToMove.ordering++;
    }

    @action
    moveToGroupAbove(lineToMove) {
        const linesInGroup = this.getLinesByGroup(lineToMove.group);
        if (lineToMove.group === 0) {
            if(linesInGroup.length === 1) {
                return;
            }

            lineToMove.ordering = 0;
            this.lines.filter(line => line !== lineToMove).forEach(line => {
                line.group = line.group ?? 0;
                line.group++;
            });
        }
        else {
            const linesInAboveGroup = this.getLinesByGroup(lineToMove.group - 1);
            lineToMove.group--;
            lineToMove.ordering = linesInAboveGroup.length;
        }

        this.fixOrderingWithinGroup(lineToMove.group + 1);
    }

    @action
    moveToGroupBelow(lineToMove) {
        const linesInGroup = this.getLinesByGroup(lineToMove.group);
        const linesInBelowGroup = this.getLinesByGroup(lineToMove.group + 1);

        if (linesInBelowGroup.length) {
            lineToMove.ordering = linesInBelowGroup.length;
            lineToMove.group++;
        }
        else if(linesInGroup.length > 1) {
            lineToMove.ordering = 0;
            lineToMove.group++;
        }
        else {
            lineToMove.ordering = 0;
        }

        this.fixOrderingWithinGroup(lineToMove.group - 1);
    }

    @action
    fixOrderingWithinGroup(group: number) {
        const linesInGroup = this.getLinesByGroup(group);
        linesInGroup.forEach((line, index) => line.ordering = index);
    }

    @action
    fixGroupsOrdering() {
        const groups = groupBy(this.lines.models, 'group');
        Object.keys(groups).map(Number).sort((a, b) => a - b).forEach((group, index) => {
            this.getLinesByGroup(group).forEach(line => line.setInput('group', index));
        });
    }

    getLineGroups(): number[] {
        return uniq(this.lines.map(line=>line.group)).sort((a, b) => a - b);
    }

    @computed get mutable() {
        return this.status === InvoiceStatus.DRAFTED;
    }
}

export class InvoiceStore<M extends Invoice = Invoice> extends Store<M> {
    Model = Invoice;
    static backendResourceName = 'invoice';
}
